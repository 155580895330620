.app {
  display: "flex";
  flex-direction: "column";
  min-height: "100vh";
}

.app-body {
  padding-bottom: "20px";
}

.foreground-card {
  box-shadow: 4px 4px 51px rgba(85, 91, 101, 0.13) !important;
  border: 1px solid #e9e9e9 !important;
}
